<template>
  <div>
    <ItemInfo></ItemInfo>
    <FilterCard></FilterCard>
    <AlertBox></AlertBox>
    <!-- <InventoryTable></InventoryTable> -->
  </div>
</template>

<script>
export default {
  components: {
    // InventoryTable: () => import("./InventoryTable"),
    ItemInfo: () => import("@/components/infoCards/ItemInfo.vue"),
    FilterCard: () => import("@/components/infoCards/NewFilterCard.vue"),
    AlertBox: () => import("@/components/infoCards/AlertBox.vue"),
  },
};
</script>

<style scoped>
div >>> .container {
  position: fixed;
  top: 60px;
  left: 0px;
  z-index: 99999;
  /* padding: 25px; */
  display: flex;
  flex-direction: column;
  gap: 10px;
  overflow-y: scroll;
  width: 100vw;
  background-color: white;
  color: black;
}

div >>> .top-bar {
  /* padding-bottom: 15px; */
  display: flex;
  order: 0;
  gap: 10px;
  justify-content: space-between;
  border-bottom: 1px solid black;
  /* padding-bottom: 5px; */
}

div >>> .mid-bar {
  order: 1;
  gap: 10px;
}

@media (min-width: 768px) {
  div >>> .container {
    position: fixed;
    top: 140px;
    left: 10px;
    z-index: 1025;
    border-radius: 25px;
    /* padding: 25px; */
    width: 370px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    box-shadow: 0px 0px 10px 5px rgb(0, 0, 0);
    overflow-y: scroll;
    max-height: 60vh;
    border-radius: 25px;
  }
  li {
    list-style-type: none;
    font-size: 16px;
    line-height: 1.9;
    border-bottom: 1px solid black;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    padding: 3px;
    text-align: right;
  }
}

/* Hide scrollbar for Chrome, Safari and Opera */
div >>> .container::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
div >>> .container {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

div >>> .exitCross {
  cursor: pointer;
  z-index: 1026;
}
</style>